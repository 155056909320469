var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pt-50 pb-5"},[_c('div',{staticClass:"container pt-5 d-flex align-items-center justify-content-center m-auto login",staticStyle:{"min-height":"65vh"}},[_c('div',{staticClass:"card m-auto col-md-3 p-5"},[_vm._m(0),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"mt-4 text-center"},[_c('h4',[_vm._v("Reset Password")]),_c('div',{staticClass:"inputbox"},[_c('validation-provider',{attrs:{"vid":"password","name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                    'is-invalid': errors[0],
                    'is-valid': !errors[0] && !pristine
                  },attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('i',{staticClass:"fa fa-lock"}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"inputbox"},[_c('validation-provider',{attrs:{"name":"password confirmation","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var pristine = ref.pristine;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password_confirmation),expression:"form.password_confirmation"}],staticClass:"form-control",class:{
                    'is-invalid': errors[0],
                    'is-valid': !errors[0] && !pristine
                  },attrs:{"type":"password","placeholder":"Re-type Password"},domProps:{"value":(_vm.form.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password_confirmation", $event.target.value)}}}),_c('i',{staticClass:"fa fa-lock"}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),(_vm.errorMessage)?_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),_c('div',{staticClass:"mybtn m-auto mt-3"},[_c('button',{staticClass:"btn btn-primary rounded-0 px-5 mb-2 mb-lg-0 me-3 addnewbtn allbtn",attrs:{"type":"submit"}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"small"},[_vm._v("Reset")])])])])])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center intro"},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/img/logo.png"),"width":"160"}})])}]

export { render, staticRenderFns }